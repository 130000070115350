.delete-btn {
  width: 38px;
  height: 38px;
  background: var(--input-bg);
  border-radius: 0.25rem;
  border: 1px solid var(--input-border);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.75rem;
  color: var(--text-color);
  &:focus {
    outline: none;
  }
  &:hover {
    background: $primary;
    color: var(--white) !important;
  }
}

.media-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid var(--input-border);
  .search {
    .form-control {
      border: none !important;
      @include media(down, $breakpoint-sm) {
        padding-left: 2rem;
        padding-right: 1rem;
      }
    }
  }
  .delete-icon {
    a {
      color: var(--gray);
    }
  }
}
.card-media {
  border: 1px solid var(--input-border);
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 1.5rem;
  .import-file {
    h6 {
      width: auto;
    }
    .asign {
      width: 170px;
      @include media(down, $breakpoint-md) {
        margin-top: 0.5rem;
      }
    }
  }
  h6 {
    font-family: $font-heading;
    font-weight: $font-regular;
    color: var(--text-color);
    font-size: 1rem;
    margin-bottom: 1.25rem;
    width: calc(100% - 360px);
  }
  .move-document {
    text-align: right;
    width: 260px;
    label {
      width: 80px;
      margin-right: 0.75rem;
    }
  }
}
.manage-files-block {
  .title {
    font-size: 0.75rem;
    line-height: 1.1rem;
    color: $gray;
    text-transform: uppercase;
    display: block;
    font-family: $font-heading;
    font-weight: $font-medium;
  }
  .manage-files {
    .card {
      border: 1px solid var(--border-color);
      .card-header {
        background: var(--light);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.25rem 0;
        border-bottom: 1px solid var(--border-color);
        .custom-checkbox {
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
        }
      }
      .card-body {
        padding: 0.25rem 0.6rem;
        background: var(--bg-white);
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        .edit-text {
          font-size: 0.75rem;
          line-height: 1.1rem;
          font-family: $font-family-base;
          font-weight: $font-medium;
          color: var(--text-color);
          display: flex;
          align-items: center;
          label {
            text-transform: capitalize !important;
          }
          .form-control {
            padding: 0.25rem !important;
            height: 1.5rem;
          }
        }
      }
      &.folder-box {
        .card-body {
          padding: 0.5rem 1rem;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
          .project-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .edit-text {
              display: flex;
              align-items: center;
              .file-icon {
                font-size: 1.5rem;
                color: $gray;
                margin-right: 0.75rem;
              }
            }
            .dropdown {
              .dropdown-toggle {
                color: $gray;
                &::after {
                  display: none;
                }
              }
              .dropdown-menu {
                padding: 0.25rem 0;
                left: 22px !important;
              }
            }
          }
        }
      }
    }
  }
}
.manage-files {
  .row {
    margin-left: -7px;
    margin-right: -7px;
    .col-lg-3 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
      padding-right: 7px;
      padding-left: 7px;
      @media screen and (min-width: 1220px) and (max-width: 1480px) {
        flex: 0 0 25% !important;
        max-width: 25% !important;
      }
      @media screen and (max-width: 1200px) {
        flex: 0 0 25% !important;
        max-width: 25% !important;
      }
      @media screen and (max-width: 992px) {
        flex: 0 0 33.33% !important;
        max-width: 33.33% !important;
      }
      @media screen and (max-width: 767px) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      @media screen and (max-width: 576px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }

    .col-lg-4 {
      flex: 0 0 25% !important;
      max-width: 25% !important;
      padding-right: 7px;
      padding-left: 7px;
      @media screen and (min-width: 1220px) and (max-width: 1480px) {
        flex: 0 0 33.33% !important;
        max-width: 33.33% !important;
      }
      @media screen and (max-width: 1200px) {
        flex: 0 0 33.33% !important;
        max-width: 33.33% !important;
      }
      @media screen and (max-width: 767px) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
      @media screen and (max-width: 576px) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

.media-body {
  padding: 1.5rem;
}

/* manage-mdeia scss */

.media-sidebar {
  width: 262px;
  height: calc(100vh - 60px);
  background: var(--light);
  border: 1px solid var(--input-border);
  border-radius: 4px 0 0 4px;
  overflow-y: auto;
  display: inline-block;
  position: absolute;
  top: 127px;
  left: 290px;
  transition: all 0.3s ease;
  z-index: 1;
  border-right: none;
  // body{
  //     &.vertical{
  //         left: 30px;
  //         top: auto;
  //     }
  // }
  @include media(down, $breakpoint-xl) {
    left: 30px;
    border-right: 1px solid var(--input-border);
    position: fixed;
    z-index: 99;
  }
  @include media(down, $breakpoint-lg) {
    left: -290px;
  }
  .title {
    padding: 10px 24px;
    display: block;
    font-size: 0.75rem;
    font-family: $font-heading;
    font-weight: $font-medium;
    letter-spacing: 1px;
    color: var(--gray);
  }
  .nav-pills {
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1.5rem;
      position: relative;
      &.active {
        background: transparent;
        .nav-title {
          .icon {
            color: $primary;
          }
          .text {
            color: $primary;
          }
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 3px;
          height: 100%;
          background: $primary;
        }
      }
      &:hover {
        background: transparent;
        .nav-title {
          .icon {
            color: $primary;
          }
          .text {
            color: $primary;
          }
        }
      }

      .nav-title {
        color: var(--gray-dark);
        font-size: 1rem;
        font-family: $font-family-base;
        .icon {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.25rem;
          vertical-align: top;
          color: var(--text-color);
        }
        .text {
          color: var(--gray-dark);
        }
      }
      .badge {
        &.file-badge {
          width: 1.5rem;
          height: 1.5rem;
          background: #ffdede;
          color: $danger;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75rem;
          font-family: $font-heading;
          font-weight: $font-medium;
          border-radius: 50%;
        }
      }
    }
  }
}
.body {
  &.vertical {
    .media-sidebar {
      left: 30px;
      top: auto;
    }
  }
}
.files-content-block {
  margin-left: 262px;
  @include media(down, $breakpoint-lg) {
    margin-left: 0;
  }
  h6 {
    width: auto;
  }
}

.open-add-file {
  .media-sidebar {
    left: 0;
  }
}

.add-file-btn {
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  color: var(--gray);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-input {
  display: none;
}
