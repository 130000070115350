@keyframes fadein {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.dropdown-item {
  color: var(--text-color);
  &.active {
    background: var(--light) !important;
    color: var(--text-color);
  }
  &:hover,
  &:focus {
    background: var(--light) !important;
    color: var(--text-color);
  }
}

header {
  box-shadow: none;
}

.navigation-bar {
  width: 100%;
  // z-index: 0;
  .navbar {
    left: $nav-sidebar-width;
    // background: var(--bg-white);
    transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    // z-index: 50001;
    @include media(down, $breakpoint-xxl) {
      left: 0;
    }
    .menu-toggle {
      .bx-menu {
        font-size: 1.5rem;
        color: var(--secondary);
        vertical-align: middle;
        cursor: pointer;
      }
    }
    // body{
    //   &.vertical{
    //     left: 0;
    //   }
    // }
  }
  .mobile-menu {
    @include media(up, $breakpoint-xxl) {
      display: none;
    }
  }
  ul.horizontal-brand {
    display: none;
    // body{
    //   &.vertical{
    //     display: inline-block;
    //     flex: 0 0 70%;
    //   }
    // }
  }
  ul.navbar-nav {
    justify-content: center;
    align-items: center;
    li {
      > a.nav-link {
        color: var(--text-color);
        font-size: 1rem;
        .flag-icon {
          margin-right: 0.5rem;
        }
      }
      .dropdown-item {
        padding: 10px 20px;
        &:active {
          background: var(--light);
        }
        .flag-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .dropdown {
    .nav-link {
      color: var(--text-color) !important;
    }
    .dropdown-menu-right {
      position: absolute;
      top: 100% !important;
      left: auto;
      // right: 12px;
      padding: 0.5rem 0;
    }
    .dropdown-menu {
      -webkit-animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal
        forwards 1 fadein;
      animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
        fadein;
      border-radius: 5px;
      html[data-theme="dark"] & {
        border: 1px solid var(--sidebar-border);
      }
    }
    .dropdown-toggle {
      .selected-language {
        @include media(down, $breakpoint-md) {
          display: none;
        }
      }
    }

    a.dropdown-user-link {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;

      .user-nav {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        float: left;
        margin-right: 0.8rem;
      }
      .user-name {
        display: inline-block;
        margin-left: 0.2rem;
        line-height: 1.2;
      }
      .user-status {
        font-size: smaller;
      }
    }
  }
.logout-btn{
  width: max-content;
  min-width: 1px;
  padding: 5px;
  height: auto;
  line-height: 1;
  margin-right: 5px;
}
  .dropdown-notification {
    padding: 13px 0;
    .dropdown-menu {
      padding: 0;
      width: 24rem;
      border-radius: 4px;
      @include media(down, $breakpoint-md) {
        width: 17rem;
      }
      @include media(down, $breakpoint-sm) {
        width: 12rem;
      }
      &:before {
        background-color: $primary;
        @include media(down, $breakpoint-md) {
          display: none;
        }
      }
      @include media(down, $breakpoint-md) {
        position: fixed;
        width: 90% !important;
        top: 70px !important;
        left: 5%;
      }
    }
    .dropdown-header {
      background-color: $primary;
      color: $white;
      @include media(down, $breakpoint-sm) {
        display: block !important;
      }
      span {
        @include media(down, $breakpoint-md) {
          display: block;
        }
        @include media(down, $breakpoint-sm) {
          font-size: 0.75rem;
        }
      }
    }
    .scrollable-container {
      &.media-list {
        max-height: 21rem;
        position: relative;
        overflow: auto !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
      }
    }
    .nav-link-label {
      position: relative;
      padding: 0 1rem;
      .badge.badge-up {
        position: absolute;
        top: -7px;
        right: 11px;
        color: var(--white);
        padding: 0.25em 0.4em 0.18rem 0.35rem;
      }
    }
    .bx-bell {
      font-size: 1.5rem;
      color: var(--text-color);
      vertical-align: middle;
      cursor: pointer;
      -webkit-text-stroke: 0.2px var(--white);
    }
    .media {
      padding: 10px;
      color: var(--text-color);
      .media-body {
        margin-left: 15px;
        padding: 0;
        @include media(down, $breakpoint-md) {
          margin-left: 5px;
          padding: 0.25rem;
        }
        .media-heading {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }

  .nav-search {
    .bx-search,
    .bx-x {
      font-size: 1.5rem;
      color: var(--text-color);
      vertical-align: middle;
      cursor: pointer;
      -webkit-text-stroke: 0.2px var(--white);
    }
    .search-input {
      float: left;
      width: 0;
      height: 0;

      .search-box {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        background: var(--white);
      }

      .search-input-icon,
      .search-input-close,
      .search-box,
      .search-list {
        display: none;
      }
      input {
        width: 0;
        border: none;
        background: none;
        line-height: 16px;
        padding: 1.7rem 3.6rem;
      }

      &.open {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        margin-left: 260px;
        height: 100%;
        width: calc(100% - 260px);
        // z-index: 1001;
        margin-top: -1px;
        box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
        background: rgba(0, 0, 0, 0.31);
        z-index: 1;
        @include media(down, $breakpoint-xxl) {
          margin-left: 0;
          width: 100%;
        }
        // body{
        //   &.vertical{
        //     width: 100%;
        //     margin-left: 0;
        //   }
        // }

        .search-input-icon,
        .search-input-close,
        .search-list {
          display: block;
        }
        .search-box {
          display: flex;
        }

        input {
          width: 100%;
          outline: none;
          background: none;
          height: 70px;
          padding: 1.7rem;
        }

        .search-list {
          padding: 20px;
          // background: rgba(0, 0, 0, 0.31);
          > li {
            padding: 10px;
            border-radius: 5px;
            background: var(--white);
            a {
              color: var(--secondary);
              .bx-error-circle {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.round {
  border-radius: 1.5rem;
}
.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}

html[data-theme="dark"] {
  .navigation-bar {
    .dropdown-notification {
      .dropdown-menu {
        li:not(.dropdown-menu-header) {
          border-left: 1px solid var(--sidebar-border);
          border-right: 1px solid var(--sidebar-border);
        }
        li:not(.dropdown-menu-header):last-child {
          border-bottom: 1px solid var(--sidebar-border);
        }
      }
    }
  }
}

body {
  &.vertical {
    .navigation-bar {
      .navbar {
        left: 0;
        .horizontal-brand {
          display: inline-block;
          flex: 0 0 70%;
          @media screen and (max-width: 1440px) {
            display: none;
          }
        }
      }
    }

    .search-input {
      &.open {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.navigation-bar {
  .navbar {
    .horizonatl-brand {
      @media screen and (max-width: 1440px) {
        display: none;
      }
    }
  }
}
