.modal {
  z-index: 9999;
  &.push-modal {
    .modal-dialog {
      max-width: 877px;
      @include media(down, $breakpoint-md) {
        max-width: 310px;
        margin: 0 auto;
      }
      .modal-content {
        background: var(--bg-white);
        .modal-header {
          border-bottom: 2px solid var(--main-border);
          .icon {
            width: 1.5rem;
            height: 1.5rem;
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            .bx {
              color: $white;
            }
          }
          .modal-title {
            color: var(--text-color);
            font-family: $font-heading;
            font-weight: $font-regular;
          }
          .close {
            color: var(--dark);
            &:focus {
              outline: none;
            }
          }
        }
        .modal-body {
          padding: 0;
          .notification-form {
            padding: 1.5rem;
          }
        }
      }
    }
  }
  &.category-modal {
    .modal-dialog {
      max-width: 498px;
    }
  }
}
.notification-form {
  .custom-radio {
    label {
      color: $gray-dark;
    }
  }
}
.demo-box {
  background: var(--login-card-bg);
  height: 448px;
  overflow: hidden;
  padding: 1.5rem;
  .nav-tabs {
    border: 1px solid var(--border-color);
    max-width: 117px;
    width: 100%;
    border-radius: 4px;
    background: var(--bg-white);
    .nav-item {
      .nav-link {
        padding: size(9) size(11);
        border: none;
        color: var(--text-color);
        &.active {
          background: $primary;
          color: $white;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          &:hover {
            color: var(--bg-white) !important;
          }
        }
        &:hover {
          color: var(--text-color) !important;
        }
      }
    }
  }
}

.modal-open {
  .modal {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
  }
}
