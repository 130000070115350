body {
  background: var(--body-bg);
}

.card {
  &.notification-card {
    box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
    transition: all 0.3s ease-in-out;
    .notification-title {
      .text {
        h5 {
          color: var(--dark);
          font-family: $font-heading;
          font-weight: $font-regular;
          line-height: 1.5rem;
        }
      }
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        background: $primary;
        border-radius: 1px;
        .bx {
          color: $white;
        }
      }
    }
    &.tabbing {
      .nav-tabs {
        display: none;
      }
      @media (min-width: 768px) {
        .nav-tabs {
          display: flex;
        }
        .card {
          border: none;
        }
        .card .card-header {
          display: none;
        }
        .card .collapse {
          display: block;
        }
      }
      @media (max-width: 767px) {
        /* 
                 * Changed selector to `.tab-content > .tab-pane` in order to override `.fade:not(.show)`
                 * In BS4 Beta `.tab-pane`s were rendered hidden by just `.fade`
                 */
        .tab-content > .tab-pane {
          display: block;
          opacity: 1;
        }
      }
    }
    .nav-tabs {
      border-bottom: 1px solid var(--main-border);
      .nav-item {
        padding-right: 1rem;
        padding-bottom: 1rem;
        .nav-link {
          background-color: var(--body-bg);
          color: var(--text-color);
          border: 1px solid transparent;
          padding: 0.625rem 1.25rem;
          border-radius: 0.25rem;
          &.active {
            background: $primary;
            color: $white !important;
            box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5);
          }
          &:hover {
            background: $primary;
            color: $white !important;
          }
        }
        &.current {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid var(--main-border);
            bottom: 0;
            left: 0;
            right: 0;
          }
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 6px solid var(--card-bg);
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
        &.nav-link {
          background-color: var(--body-bg);
          color: var(--text-color);
          border: 1px solid transparent;
          padding: 0.625rem 1.25rem;
          border-radius: 0.25rem;
          &.active {
            background: $primary;
            color: $white !important;
            box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5);
          }
          &:hover {
            background: $primary;
            color: $white !important;
          }
        }
      }
    }
    &.tabs-block {
      .nav-tabs {
        border-bottom: 1px solid var(--main-border);
        padding-bottom: 1rem;
        .nav-item {
          &.nav-link {
            background-color: var(--body-bg);
            color: var(--text-color);
            border: 1px solid transparent;
            padding: 0.625rem 1.25rem;
            border-radius: 0.25rem;
            margin-right: 1rem !important;
            &.active {
              background: $primary;
              color: $white !important;
              box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5);
            }
            &:hover {
              background: $primary;
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.notification-card {
  .content {
    padding: 2rem;
    @include media(down, $breakpoint-md) {
      padding: 1rem;
    }
  }
  .img-demo1 {
    width: 1rem;
    height: 1rem;
    background: var(--login-card-bg);
    margin: 0.75rem 1.25rem;
    overflow: hidden;
    svg {
      fill: var(--gray);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .img-demo2 {
    width: 5rem;
    height: 5rem;
    background: var(--login-card-bg);
    overflow: hidden;
    svg {
      fill: var(--gray);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .site-logo {
    width: calc(100% - 6rem);
  }
  .img-demo3 {
    max-width: 18.25rem;
    width: 100%;
    height: 5.75rem;
    background: var(--login-card-bg);
    overflow: hidden;
    svg {
      fill: var(--gray);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  h6 {
    color: var(--text-color);
    font-family: $font-heading;
    font-weight: $font-regular;
  }
  .sm-title {
    font-family: $font-family-base;
    color: var(--text-color);
  }
}

.collapse-setting {
  .card {
    background: var(--light-shade-1);
    border-radius: 0.25rem;
    border-color: $light-shade;
    padding: 0;
    .card-header {
      background: var(--light-shade-1);
      border: none;
      padding: 0;
      .collapse-title {
        padding: 1.8rem 1.8rem;
        @include media(down, $breakpoint-md) {
          padding: 1rem;
        }
        .icon {
          i {
            font-size: 1.5rem;
          }
        }
        h5 {
          font-weight: $font-regular;
          color: var(--dark);
        }
        &.collapsed {
          &::after {
            content: "\e9ac";
          }
        }
        .collapse-arrow {
          width: 3rem;
          padding: 1rem;
          .arrow {
            position: relative;
            width: 3rem;
            cursor: pointer;
            &::after {
              content: "\e9ac";
              font-family: $font-boxicon;
              position: absolute;
              right: size(17);
              top: size(-18);
              color: var(--text-color);
              font-size: 1.5rem;
            }
          }
          &.collapsed {
            .arrow {
              &::after {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    .card-body {
      border-top: 1px solid var(--main-border);
      padding: 1.8rem;
      @include media(down, $breakpoint-md) {
        padding: 1rem;
      }
    }
  }
}

/* import File */

.card {
  &.import-card {
    padding: 2rem;
    @include media(down, $breakpoint-md) {
      padding: 1rem;
    }
  }
}

.text-gray-dk {
  color: $gray-dark !important;
}

.tab-content {
  .card {
    .card-header {
      background: var(--light);
      h5 {
        a {
          color: var(--text-color);
        }
      }
    }
  }
}

.collapse-arrow {
  width: 3rem;
  padding: 1rem;
  .arrow {
    position: relative;
    width: 3rem;
    cursor: pointer;
    &::after {
      content: "\e9b9";
      font-family: $font-boxicon;
      position: absolute;
      right: size(17);
      top: size(-18);
      color: var(--text-color);
      font-size: 1.5rem;
    }
  }
  // &.collapsed {
  //     .arrow {
  //         &::after {
  //             transform: rotate(180deg);
  //         }
  //     }
  // }
}
.arrow-down {
  min-width: 3rem;
  .collapse-arrow {
    .arrow {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
.arrow-up {
  min-width: 3rem;
}
